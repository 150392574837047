.text-time {
  font-size: 1em;
  color: rgb(47, 237, 13);
}

.text-link {
  text-decoration: none;
  font-weight: bold;
  font-size: 1.1em;
}

.card-img-top {
  width: 100%;
  height: 12.5vw;
  object-fit: cover;
}
